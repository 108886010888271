<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[
      { 'expanded': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row">

          <!-- Logo & Text -->
          <li class="nav-item mr-auto">
            <b-link
              class="navbar-brand"
              to="/"
            >
              <span class="brand-logo">
                <b-img
                  :src="appLogoImage"
                  alt="logo"
                />
              </span>
              <h2 class="brand-text">
                {{ appName }}
              </h2>
            </b-link>
          </li>

          <!-- Toggler Button -->
          <li class="nav-item nav-toggle">
            <b-link class="nav-link modern-nav-toggle">
              <feather-icon
                icon="XIcon"
                size="20"
                class="d-block d-xl-none"
                @click="toggleVerticalMenuActive"
              />
              <feather-icon
                :icon="collapseTogglerIconFeather"
                size="20"
                class="d-none d-xl-block collapse-toggle-icon"
                @click="toggleCollapsed"
              />
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div
      :class="{'d-block': shallShadowBottom}"
      class="shadow-bottom"
    />

    <!-- main menu content-->
    <vue-perfect-scrollbar
      v-if="isAPIFetchDone"
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }"
    >
      <vertical-nav-menu-items
        :items="navMenuItems"
        class="navigation navigation-main"
      />
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
  </div>
</template>

<script>
import navMenuItemss from '@/navigation/vertical'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BLink, BImg } from 'bootstrap-vue'
import { provide, computed, ref } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeConfig } from '@themeConfig'
import axios from '@/libs/axios' // eslint-disable-line
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from './useVerticalNavMenu'

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      navMenuItems: null,
      isAPIFetchDone: false,
      userBadgesCount: {},
    }
  },
  computed: {
    userBadges() {
      return this.$store.state.home.userBadges
    },
    adminBadges() {
      return this.$store.state.home.adminBadges
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    '$store.state.home.userBadges': function () {
      const { mCount } = this.$store.state.home.userBadges
      this.updateUserBadges(mCount)
    },
    // eslint-disable-next-line func-names
    '$store.state.home.adminBadges': function () {
      const { mCount } = this.$store.state.home.adminBadges
      this.updateAdminBadges(mCount)
    },
  },
  created() {
    this.navMenuItems = navMenuItemss
    const user = this.$store.state.auth.userData
    // todo: get data from userData
    if (user.isAdmin) {
      this.$store.dispatch('home/fetchAdminBadges').then(res => { // eslint-disable-line
        const info = res.mCount
        this.updateAdminBadges(info)
        this.isAPIFetchDone = true
      })
    } else {
      this.$store.dispatch('home/fetchUserBadges').then(res => { // eslint-disable-line
        const info = res.mCount
        this.updateUserBadges(info)
        this.isAPIFetchDone = true
      })
    }
  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props)

    const { skin } = useAppConfig()

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))

    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    return {
      navMenuItemss,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
    }
  },
  methods: {
    updateUserBadges(info) {
      this.navMenuItems.forEach((element, index) => {
        if (element.route === 'product_updates') {
          this.navMenuItems[index].tag = info.product_updates_count
        }
        if (element.resource === 'Ticket') {
          this.navMenuItems[index].tag = info.ticketCount
          this.navMenuItems[index].children[1].tag = info.ticketCount
        }
        if (element.route === 'news') {
          this.navMenuItems[index].tag = info.newsCount
        }
      })

      this.$forceUpdate()
    },

    updateAdminBadges(info) {
      let i = 0
      let t = 0
      let b = 0

      const billingPending = info.pendingRequests + info.pendingWire + info.pendingBtc

      this.navMenuItems.forEach((element, index) => {
        if (element.resource === 'Billing') i = index
        if (element.resource === 'Ticket') t = index
        if (element.resource === 'Admin' && element.children) {
          element.children.forEach(child => {
            if (child.route === 'form-leads-pending') b = index
          })
        }
      })

      this.navMenuItems[i].tag = billingPending
      this.navMenuItems[i].children[6].tag = info.pendingRequests
      this.navMenuItems[i].children[11].tag = info.pendingWire

      this.navMenuItems[t].tag = info.ticketCount + info.admins_statistics
      this.navMenuItems[t].children[1].tag = info.ticketCount
      this.navMenuItems[t].children[4].tag = info.admins_statistics

      this.navMenuItems[b].tag = info.pendingUsersCount
      this.navMenuItems[b].children[0].tag = info.pendingUsersCount

      this.$forceUpdate()
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
</style>
