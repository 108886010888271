<template>
  <div class="navbar-container d-flex content align-items-center nave-c">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
      <div style="max-width: 600px; margin-left: 13px; margin-right: 3px;">
        Welcome to our new client dashboard. In case of any issues feel free to open a support ticket with us.
      </div>
    </div>

    <b-navbar-nav
      class="nav align-items-center ml-auto"
      style="min-width: fit-content;"
    >
      <div v-if="isAffiliate && currentBalance > 0">
        Balance: € {{ currentBalance }}
      </div>
      <notification-dropdown />
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import NotificationDropdown from './NotificationDropdown.vue'
import UserDropdown from './UserDropdown.vue'

export default {
  components: {
    BLink,
    BNavbarNav,
    NotificationDropdown,
    UserDropdown,

    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      oldDashboard: process.env.VUE_APP_PORTAL,
      currentBalance: 0,
      isAffiliate: false,
      accountType: 1,
      uRole: '',
    }
  },
  computed: {
    userData() {
      return this.$store.state.auth.userData
    },
  },
  mounted() {
    this.getAffiliateDetails()
  },
  methods: {
    async getAffiliateDetails() {
      this.accountType = Number(this.userData.account_type)
      this.uRole = this.userData.userRole
      this.oldDashboard += 'home'
      this.checkIfAffiliate()

      if (this.isAffiliate) {
        const response = await this.$store.dispatch('affiliate/fetchAffiliateData')
        if (typeof response === 'object') this.currentBalance = parseFloat(response.current_balance).toFixed(2)
      }
    },

    checkIfAffiliate() {
      if (this.userData.userRole === 'Affiliate' || this.userData.isUserAffiliate) {
        this.isAffiliate = true
      }
    },
    changeType() {
      this.$router.push('/tickets/new-ticket')
    },
  },
}
</script>
