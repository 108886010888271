<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      COPYRIGHT © 2016 - {{ new Date().getFullYear() }} © TRAFFIC MANAGER GROUP S.R.L.
    </span>

    <span class="float-md-right d-md-block">
      <b-link
        class="ml-25"
        :href="`${portal}legal#privacy-policy`"
        target="_blank"
      >Privacy Policy and GDPR</b-link> | <b-link
        class="ml-25"
        :href="`${portal}legal#client-agreement`"
        target="_blank"
      >Client Agreement</b-link> | <b-link
        class="ml-25"
        :href="`${portal}processing_of_personal_data_agreement_according_to_GDPR`"
        target="_blank"
      >Processing of Personal Data Agreement</b-link> <br> <b-link
        class="ml-25"
        :href="`${portal}legal#terms-of-use`"
        target="_blank"
      >Terms of Use</b-link> | <b-link
        class="ml-25"
        :href="`${portal}legal#billing-refund-policy`"
        target="_blank"
      >Billing & Refund Policy</b-link> | <b-link
        class="ml-25"
        :href="`${portal}legal#data-processing-agreement`"
        target="_blank"
      >Data Processing Agreement</b-link>
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
  data() {
    return {
      portal: process.env.VUE_APP_PORTAL,
    }
  },
}
</script>
