<template>
  <b-nav-item @click="updateTheme">
    <div class="d-flex align-items-center">
      <feather-icon
        size="21"
        :icon="`${isDark ? 'Sun' : 'Moon'}Icon`"
        :style="isDark ? 'color:white;' : 'color:#4b4b4b;'"
      />
      <b-button
        class="ml-1"
        :variant="isDark ? 'light' : 'dark'"
        size="sm"
      >
        {{ isDark ? 'Light' : 'Dark' }} theme
      </b-button>
    </div>
  </b-nav-item>
</template>

<script>
/* eslint-disable */
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'
import { BNavItem, BButton } from 'bootstrap-vue'

export default {
  components: {
    BNavItem,
    BButton,
  },
  setup() {
    const { skin } = useAppConfig();

    const isDark = computed(() => skin.value === 'dark');

    return { skin, isDark };
  },
  mounted() {
    setTimeout(() => {
      this.skin = this.getCookie('theme') || 'light';
    }, 500);
  },
  methods: {
    updateTheme() {
      this.skin = this.isDark ? 'light' : 'dark';
      this.setCookie('theme', this.skin, 999);
    },

    setCookie(cname, cvalue, exdays) {
      const d = new Date();
      d.setTime(d.getTime() + (exdays*24*60*60*1000));
      const expires = 'expires='+ d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    },

    getCookie(cname) {
      const name = cname + "=";
      const decodedCookie = decodeURIComponent(document.cookie);
      const ca = decodedCookie.split(';');
      for(let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1);
        if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
      }
      return "";
    }
  },
}
</script>
