<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ $store.state.auth.userData.forename }}
        </p>
        <span class="user-status">{{ userStatus }}</span>
      </div>
      <b-avatar
        size="40"
        variant="light-primary"
        badge
        :src="userAvatar.avatar"
        class="badge-minimal"
        badge-variant="success"
      />
    </template>

    <b-dropdown-item
      link-class="d-flex align-items-center"
      :to="{path:'/profile'}"
    >
      <feather-icon
        size="16"
        icon="UserIcon"
        class="mr-50"
      />
      <span>Profile</span>
    </b-dropdown-item>

    <b-dropdown-item
      v-if="user.userRole === 'User' || user.userRole === 'Affiliate'"
      link-class="d-flex align-items-center"
      :to="{path:'/account'}"
    >
      <feather-icon
        size="16"
        icon="UserIcon"
        class="mr-50"
      />
      <span>My Account</span>
    </b-dropdown-item>

    <b-dropdown-divider />

    <b-dropdown-item
      v-if="$store.state.auth.impersonate"
      link-class="d-flex align-items-center"
      @click="exitImpersonation"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>Exit Impersonation</span>
    </b-dropdown-item>

    <!-- <b-dropdown-item
      v-if="user.userRole === 'Admin' && !$store.state.auth.impersonate"
      link-class="d-flex align-items-center"
      :to="{path:'/changelog'}"
    >
      <feather-icon
        size="16"
        icon="TerminalIcon"
        class="mr-50"
      />
      <span>Changelog</span>
    </b-dropdown-item> -->
    <b-dropdown-item
      v-if="user.userRole === 'Admin' && !$store.state.auth.impersonate"
      link-class="d-flex align-items-center"
      :to="{path:'/global-logs'}"
    >
      <feather-icon
        size="16"
        icon="TerminalIcon"
        class="mr-50"
      />
      <span>Global logs</span>
    </b-dropdown-item>
    <b-dropdown-item
      v-if="user.userRole === 'Admin' && !$store.state.auth.impersonate"
      link-class="d-flex align-items-center"
      @click="signEfactura"
    >
      <feather-icon
        size="16"
        icon="FileTextIcon"
        class="mr-50"
      />
      <span>Sign E-Factura</span>
    </b-dropdown-item>
    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="logout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>Logout</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import VueCookies from 'vue-cookies'
import Vue from 'vue'

export const eventBus = new Vue()

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
      avatarSrc: '',
      userData: JSON.parse(localStorage.getItem('userData')),
      avatarText,
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.userData
    },
    userAvatar() {
      return this.$store.state.auth.userAvatar
    },
    userStatus() {
      if (this.userData.userRole === 'Affiliate') return 'affiliate'
      if (this.userData.isUserAffiliate) return 'user'
      return this.userData.userRole
    },
  },
  methods: {
    signEfactura() {
      window.location.href = `${process.env.VUE_APP_PORTAL}efactura/sign-efactura`
    },
    exitImpersonation() {
      this.$store.dispatch('auth/exitImpersonation').then(() => { window.location = '/' })
    },
    logout() {
      localStorage.clear()
      VueCookies.keys().forEach(cookie => {
        VueCookies.remove(cookie)
      })
      this.$http.post('/api/logout')
        .then(() => {
          window.location.href = `${process.env.VUE_APP_PORTAL}dashboard-logout`
        })
        .catch(() => {
          window.location.href = `${process.env.VUE_APP_PORTAL}dashboard-logout`
        })
    },
  },
}
</script>

<style>
.header-navbar .navbar-container ul.navbar-nav li.dropdown-user .dropdown-menu {
  width: 15rem !important;
}
</style>
