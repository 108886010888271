<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="newMessages != null ? newMessages.length : 0"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li
      v-if="newMessages"
      class="dropdown-menu-header"
    >
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Messages
        </h4>
        <b-badge
          v-if="newMessages.length>0"
          pill
          variant="light-primary"
          style="cursor:pointer"
          @click="markAllAsRead"
        >
          Mark all as read
        </b-badge>
        <b-badge
          pill
          variant="light-primary"
        >
          {{ newMessages != null ? newMessages.length : 0 }} New
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      v-if="newMessages"
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- Account Notification -->
      <b-link
        v-for="notification in newMessages"
        :key="notification.id"
        :href="notification.ticket_id ? '/tickets/' + notification.ticket_id : '/messages/' + notification.id"
        @click="markAsRead(notification.id)"
      >
        <b-media>
          <template #aside>
            <b-avatar
              size="32"
              :src="notification.author_avatar"
            />
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.author_email }}
            </span>
          </p>
          <small class="notification-text">{{ notification.msg_subject }}</small>
        </b-media>
      </b-link>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li
      v-if="user"
      class="dropdown-menu-footer"
    >
      <b-button
        v-if="user.userRole==='Admin'"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        href="/messages/systemadmin"
      >
        View all system messages
      </b-button>
      <b-button
        v-else
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        href="/messages/system"
      >
        View all system messages
      </b-button>
    </li>

  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BAvatar, BButton, BLink,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import axios from '@/libs/axios' // eslint-disable-line

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BLink,
    BMedia,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
  },
  directives: {
    Ripple,
  },
  computed: {
    newMessages() {
      return this.$store.state.messages.newMessages
    },
    user() {
      return this.$store.state.auth.userData
    },
  },
  created() {
    this.fetchNewMessages()
  },
  methods: {
    fetchNewMessages() {
      this.$store.dispatch('messages/fetchNewMessages')
    },
    async markAllAsRead() {
      const url = '/api/messages/mark_all_read'
      await axios.post(url).then(() => {
        this.notify({
          text: 'Success!',
          variant: 'success',
        })
      })
      setTimeout(() => {
        window.location.reload()
      }, '1000')
    },
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }
    return {
      perfectScrollbarSettings,
    }
  },
}
</script>

<style>

</style>
